/**
 * @file App route config

 */

import React from 'react';
// import { generatePath } from 'react-router-dom'

export enum RouteKey {
  Login,
  Guideline,
  Home,
  ForgotPassword,
  ResetPassword,
  // ListClasses,
  Lesson,
  MyPage,
  EvaluationView,
  UserList,
  MockClassAssignment,
  EvaluationForm,
  EvaluationReview,
  EvaluationDemoReviewVideo,
  FeedbackForm,
  CSDemoRequestingList,
  ApplyHistory,
  Supporter,
  LessonViewOnly,
  MailTemplate,
  AIDataTraining,
  NotPermission,
  Contact,
  RequestSystem,
  EmergencyContact,
  ProcessYoutube,
  ProcessYoutubeFail,
  AdminPreviewFormFeedback
}

export interface RouteConfig {
  id: RouteKey;
  name: string;
  path: string;
  subPath?: string;
  main: string;
  icon?: React.ReactElement;
  pather?(...args: Array<any>): string;
}

export const routeMap: ReadonlyMap<RouteKey, RouteConfig> = new Map(
  [
    {
      id: RouteKey.Home,
      name: 'Home',
      path: '/dashboard',
      main: '/dashboard',
    },
    {
      id: RouteKey.Login,
      name: 'Login',
      path: '/',
      main: '/',
    },
    {
      id: RouteKey.Guideline,
      name: 'Guideline',
      path: '/guideline',
      main: '/guideline',
    },
    {
      id: RouteKey.ForgotPassword,
      name: 'ForgotPassword',
      path: '/forgotpassword',
      main: '/forgotpassword',
    },
    {
      id: RouteKey.ResetPassword,
      name: 'ResetPassword',
      path: '/password-reset/:uidb64/:token',
      main: '/password-reset',
    },
    // {
    //   id: RouteKey.ListClasses,
    //   name: 'ListClasses',
    //   path: '/list-classes',
    //   main: '/list-classes',
    // },
    {
      id: RouteKey.MyPage,
      name: 'MyPage',
      path: '/my-page',
      main: '/my-page',
    },
    {
      id: RouteKey.EvaluationView,
      name: 'EvaluationView',
      path: '/evaluation-view/:id/',
      main: '/evaluation-view',
    },
    {
      id: RouteKey.UserList,
      name: 'UserList',
      path: '/users',
      main: '/users',
    },
    {
      id: RouteKey.MockClassAssignment,
      name: 'MockClassAssignment',
      path: '/mock-class-assignment/:courseId/demos/:demoId',
      main: '/mock-class-assignment',
    },
    {
      id: RouteKey.EvaluationForm,
      name: 'EvaluationForm',
      path: '/evaluation-form/:id',
      main: '/evaluation-form',
    },
    {
      id: RouteKey.EvaluationReview,
      name: 'EvaluationReview',
      path: '/evaluation-review/:courseId/',
      main: '/evaluation-review',
    },
    {
      id: RouteKey.EvaluationDemoReviewVideo,
      name: 'EvaluationDemoReviewVideo',
      path: '/course-demo/:id/demo-request/:demoId',
      main: '/course-demo',
    },
    {
      id: RouteKey.FeedbackForm,
      name: 'FeedbackForm',
      path: '/feedback-form/course/:courseId/demo/:demoId',
      main: '/feedback-form',
    },
    {
      id: RouteKey.CSDemoRequestingList,
      name: 'CSDemoRequestingList',
      path: '/cs-demo-requesting-list',
      main: '/cs-demo-requesting-list',
    },
    {
      id: RouteKey.ApplyHistory,
      name: 'CSDemoRequestingList',
      path: '/apply-history',
      main: '/apply-history',
    },
    {
      id: RouteKey.Lesson,
      name: 'Lesson',
      path: '/lesson/:courseId?',
      main: '/lesson/:courseId?',
    },
    {
      id: RouteKey.LessonViewOnly,
      name: 'Lesson',
      path: '/lesson/view/:courseId?',
      main: '/lesson/view/:courseId?',
    },
    {
      id: RouteKey.MailTemplate,
      name: 'MailTemplate',
      path: '/mail-template/:mailId?',
      main: '/mail-template/:mailId?',
    },
    {
      id: RouteKey.AIDataTraining,
      name: 'AIDataTraining',
      path: '/data-traning',
      main: '/data-traning',
    },
    {
      id: RouteKey.NotPermission,
      name: 'NotPermission',
      path: '/not-permission',
      main: '/not-permission',
    },
    {
      id: RouteKey.Contact,
      name: 'Contact',
      path: '/contact',
      main: '/contact',
    },
    {
      id: RouteKey.RequestSystem,
      name: 'Contact',
      path: 'https://forms.gle/SJfD5ZxBxqsvgbnBA',
      main: 'https://forms.gle/SJfD5ZxBxqsvgbnBA',
    },
    {
      id: RouteKey.EmergencyContact,
      name: 'Contact',
      path: 'https://forms.gle/RrRdMQ4LtBZ22Vov6',
      main: 'https://forms.gle/RrRdMQ4LtBZ22Vov6',
    },
    {
      id: RouteKey.ProcessYoutube,
      name: 'ProcessYoutube',
      path: '/youtube-in-progress',
      main: '/youtube-in-progress',
    },
    {
      id: RouteKey.ProcessYoutubeFail,
      name: 'ProcessYoutube',
      path: '/youtube-failed',
      main: '/youtube-failed',
    },
    {
      id: RouteKey.AdminPreviewFormFeedback,
      name: 'AdminPreviewFormFeedback',
      path: '/admin-preview-feedback/course/:courseId/demo/:demoId',
      main: '/admin-preview-feedback',
    },
  ].map(route => [route.id, route]),
);

export const rc = (routeKey: RouteKey): RouteConfig => {
  return routeMap.get(routeKey)!;
};
export const rcByPath = (routePath: string) => {
  return Array.from(routeMap.values()).find(route => route.path === routePath);
};
export const isRoute = (routePath: string, routeKey: RouteKey) => {
  return routeMap.get(routeKey)?.path === routePath;
};
export const getRouteNameBySubpath = (subpath: string) => {
  const routeArray = Array.from(routeMap.values());
  return routeArray.find(route =>
    route.subPath ? route.subPath === subpath : route.path === subpath,
  );
};
