import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import Label from '../../components/atoms/Label';
import TextField from '../../components/atoms/TextField';
import Button from '../../components/atoms/Button';
import { Tab, TabPane } from '../../components/atoms/Tab';
import TabContent1 from './included/TabContent1';
import TabContent2 from './included/TabContent2';
import TabContent3 from './included/TabContent3';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { BasicModal } from '../../components/molecules/BasicModal';
import {
  courseDetailService,
  evaluateUpdateService,
  GPTPromptService,
} from '../../services/CSSevice';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, ReloadIcon, SaveIcon } from '../../constants';
import { ICourseSchema, IPrompt } from '../../constants/types';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  regexRouter,
  showToastMessage,
} from '../../utils/common';
import { rc, RouteKey } from '../../constants/router';
import { Skeleton } from 'antd';
import {
  confirmRatingLesson,
  courseNotFoundOrAlreadyReviewed,
  temporarilySaved,
  txtEvaluationConfirmSuccess,
} from '../../constants/message';
import { Loader } from '../../components/atoms/Loader';
import axios from 'axios';
import _ from 'lodash';

export interface IPropsEvaluation {
  EvaluationForm: any;
  courseInfo: ICourseSchema | any;
  showModal: Function;
  setPromptDT: any;
  resCheck: any;
  setResCheck: React.SetStateAction<any>;
  responseGPT?: any;
  isSubmit?: boolean;
  setIsSubmit?: any;
  setContentText: Function;
  curTab?: string;
  setIsTemporarySaved: React.SetStateAction<any>;
  isModalOpen: boolean;
  valueChange: string;
  setValueChange: React.SetStateAction<any>;
}

export function splitAndMapValue(value: string) {
  if (!value) {
    return null;
  }

  return value.split(/\r?\n/).map((line: string, index: number) => (
    <span key={line}>
      {line}
      <br />
    </span>
  ));
}

const EvaluationForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [courseInfo, setCourseInfo] = useState<ICourseSchema>();
  const [typeSave, setTypeSave] = useState<number>(0);
  const [promptDT, setPromptDT] = useState<IPrompt>();
  const [responseGPT, setResponseGPT] = useState<any>();
  const [resCheck, setResCheck] = useState<any>();
  const [isSubmit, setIsSubmit] = useState(false);
  const [contentText, setContentText] = useState('');
  const [isGenarate, setIsGenarate] = useState(false);
  const [curTab, setCurTab] = useState('1');
  const [isLoading, setIsLoading] = useState(false);
  const [isTemporarySaved, setIsTemporarySaved] = useState(false);
  const [cancelCallGPT, setCancelCallGPT] = useState<any>(null);
  const [valueChange, setValueChange] = useState('');

  const PromprtForm = useForm();
  const EvaluationForm = useForm();
  const { handleSubmit, reset } = EvaluationForm;
  const navigate = useNavigate();
  const { id } = useParams();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const resetState = () => {
    setResponseGPT('');
    setContentText('');
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setIsSubmit(true);
    resetState();
  };
  const handleCancel = () => {
    if (cancelCallGPT) {
      cancelCallGPT?.cancel();
    }
    setIsGenarate(false);
    setIsModalOpen(false);
    resetState();
  };

  const handleClose = () => {
    setIsConfirm(false);
    resetState();
    setTypeSave(0);
  };
  const handleTabChange = (tabKey: string) => {
    setCurTab(tabKey);
  };

  const getCSDetail = async () => {
    setIsLoading(true);
    await courseDetailService(id || '').then(result => {
      setCourseInfo(result);
      setIsLoading(false);
      if (result?.status !== 3) {
        navigate(rc(RouteKey.Home).path);
      }
    });
  };

  const evalFormSubmit = async (d: any) => {
    setIsLoading(true);
    if (typeSave === 1) {
      setIsTemporarySaved(true);
      const response: any = await evaluateUpdateService(id || '', d);
      if (response?.status === 200) {
        await getCSDetail();
        setIsTemporarySaved(false);
        await showToastMessage('success', '', temporarilySaved);
      }
    } else if (typeSave === 2) {
      const result: any = await evaluateUpdateService(id || '', {
        ...d,
        status: 4,
      });
      if (result?.status === 200) {
        setTypeSave(0);
        await showToastMessage(
          'success',
          '',
          txtEvaluationConfirmSuccess(courseInfo?.title || ''),
        );
        navigate(regexRouter(rc(RouteKey.Home).path));
      } else {
        setTypeSave(0);
        await showToastMessage(
          'error',
          '授業評価',
          courseNotFoundOrAlreadyReviewed(courseInfo?.title || ''),
        );
      }
    }
    setIsLoading(false);
  };

  const callGPT = async () => {
    if (promptDT) {
      setIsGenarate(true);
      const source = axios.CancelToken.source();
      setCancelCallGPT(source);
      await GPTPromptService(
        id || '',
        {
          ...promptDT,
          prompt: PromprtForm?.getValues('content'),
        },
        source,
      ).then(result => {
        if (result?.status === 200) {
          setResponseGPT(result.data);
          setResCheck(result.data);
          setIsGenarate(false);
        }
      });
    }
  };

  useEffect(() => {
    getCSDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (courseInfo) {
      reset(courseInfo);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseInfo]);

  useEffect(() => {
    if (typeSave === 1) {
      evalFormSubmit(EvaluationForm.getValues());
      setTypeSave(0);
    } else if (typeSave === 2) {
      setIsConfirm(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EvaluationForm.watch, typeSave]);

  useEffect(() => {
    if (!isModalOpen) {
      setResponseGPT(null);
      PromprtForm.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    if (isTemporarySaved) {
      setResCheck(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemporarySaved]);

  useEffect(() => {
    setResCheck(null);
  }, [curTab]);

  useEffect(() => {
    PromprtForm.setValue('content', contentText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentText]);

  return (
    <>
      <div className="max-w-full">
        <TabLinks className="mb-4" />
        <div className="px-8 py-5 bg-white mb-4">
          <Heading className="text-blue-800">
            <span
              className="mr-2 inline-block align-middle cursor-pointer"
              onClick={() => navigate(rc(RouteKey.Home).path)}
            >
              <ArrowLeft color="#6599ED" />
            </span>
            授業評価
          </Heading>
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div>
              <Label label="ID">
                <TextField
                  type="text"
                  classNameChild="w-full block"
                  placeholder={courseInfo?.id?.toString()}
                  disabled
                />
              </Label>
            </div>
            <div className="col-span-2">
              <Label label="タイトル">
                <TextField
                  type="text"
                  classNameChild="w-full block"
                  placeholder={courseInfo?.title}
                  disabled
                />
              </Label>
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading}>
          <div className="mx-8 mb-20">
            <div className="relative p-4 bg-white rounded border border-color-gray-40">
              <div className="overflow-hidden">
                <form onSubmit={handleSubmit(evalFormSubmit)}>
                  <div className="float-right ms-auto pl-4 flex gap-x-2">
                    <Button
                      type="button"
                      variant="outline-primary"
                      size="small"
                      className="min-w-[142px]"
                      onClick={() => setTypeSave(1)}
                    >
                      <span className="inline-block relative top-1 me-2">
                        <SaveIcon />
                      </span>
                      一時保存
                    </Button>
                    <Button
                      type="button"
                      variant="success"
                      iconPrefix={faCheckDouble}
                      size="small"
                      className="min-w-[142px]"
                      // onClick={showModal}
                      onClick={() => setTypeSave(2)}
                    >
                      評価確定
                    </Button>
                  </div>
                  <div>
                    <Tab
                      defaultActiveKey="1"
                      className="tab-evaluation-view"
                      onChange={handleTabChange}
                    >
                      <TabPane tabKey="1" tab="総合評価">
                        <TabContent1
                          courseInfo={courseInfo}
                          EvaluationForm={EvaluationForm}
                          showModal={showModal}
                          setPromptDT={setPromptDT}
                          resCheck={resCheck}
                          setResCheck={setResCheck}
                          isSubmit={isSubmit}
                          setIsSubmit={setIsSubmit}
                          setContentText={setContentText}
                          curTab={curTab}
                          setIsTemporarySaved={setIsTemporarySaved}
                          isModalOpen={isModalOpen}
                          valueChange={valueChange}
                          setValueChange={setValueChange}
                        />
                      </TabPane>
                      <TabPane tabKey="2" tab="パート">
                        <TabContent2
                          courseInfo={courseInfo}
                          EvaluationForm={EvaluationForm}
                          showModal={showModal}
                          setPromptDT={setPromptDT}
                          resCheck={resCheck}
                          setResCheck={setResCheck}
                          isSubmit={isSubmit}
                          setIsSubmit={setIsSubmit}
                          setContentText={setContentText}
                          curTab={curTab}
                          setIsTemporarySaved={setIsTemporarySaved}
                          isModalOpen={isModalOpen}
                          valueChange={valueChange}
                          setValueChange={setValueChange}
                        />
                      </TabPane>
                      <TabPane tabKey="3" tab="評価内訳">
                        <TabContent3
                          courseInfo={courseInfo}
                          EvaluationForm={EvaluationForm}
                          showModal={showModal}
                          setPromptDT={setPromptDT}
                          resCheck={resCheck}
                          setResCheck={setResCheck}
                          isSubmit={isSubmit}
                          setIsSubmit={setIsSubmit}
                          setContentText={setContentText}
                          curTab={curTab}
                          setIsTemporarySaved={setIsTemporarySaved}
                          isModalOpen={isModalOpen}
                          valueChange={valueChange}
                          setValueChange={setValueChange}
                        />
                      </TabPane>
                    </Tab>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Loader>
      </div>

      <BasicModal
        title="GPTプロンプト"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width="680px"
        footer={[
          <div className="grid">
            <Button
              type="button"
              variant="primary"
              size="medium"
              className={
                'w-full ' +
                `${!_.trim(PromprtForm.watch('content')) && 'opacity-50 cursor-no-drop'} ${isGenarate && 'opacity-50 cursor-wait'}`
              }
              onClick={handleOk}
              disabled={!_.trim(PromprtForm.watch('content')) || isGenarate}
            >
              コメントへ反映する
            </Button>
          </div>,
        ]}
      >
        <form onSubmit={PromprtForm.handleSubmit(callGPT)}>
          <Label label="内容" className="mb-3">
            <Controller
              control={PromprtForm.control}
              name="content"
              render={({ field: { value, onChange } }) => {
                return (
                  <TextFieldCus
                    value={typeof value === 'string' ? value : contentText}
                    onChange={onChange}
                    type="textarea"
                    className="w-full block h-min-[92px]"
                    placeholder=""
                  />
                );
              }}
            />
          </Label>
          <div className="text-right mb-5">
            <button
              type="submit"
              disabled={isGenarate || !_.trim(PromprtForm.watch('content'))}
              className={
                'inline-block text-base font-semibold py-3 px-2 rounded-xl text-center text-color-green-700 bg-color-green-100-2 hover:bg-color-green-200 ' +
                `${!_.trim(PromprtForm.watch('content')) && 'opacity-50 cursor-no-drop'} ${isGenarate && 'opacity-50 cursor-wait'}`
              }
            >
              プロンプト内容送信
              <span className="inline-block ml-2 align-top">
                <ReloadIcon />
              </span>
            </button>
          </div>
        </form>
        <Label label="GPTからの反応内容">
          {isGenarate ? (
            <Skeleton />
          ) : (
            <TextFieldCus
              type="textarea"
              classNameChild="w-full block h-min-[92px]"
              readOnly={true}
              value={responseGPT?.result}
            />
          )}
        </Label>
      </BasicModal>

      <BasicModal
        title={''}
        open={isConfirm}
        onOk={() => setIsConfirm(false)}
        onCancel={() => handleClose()}
        centered
        width="680px"
        footer={[
          <form onSubmit={handleSubmit(evalFormSubmit)}>
            <div className="flex flex-row-reverse">
              <Button
                type="button"
                variant="primary"
                size="small"
                className="w-[20%]"
                onClick={() => {
                  evalFormSubmit(EvaluationForm.getValues());
                  setIsConfirm(false);
                }}
              >
                評価確定
              </Button>
              <Button
                type="button"
                variant="outline-tertiary"
                size="small"
                className="w-[20%] mr-4"
                onClick={() => handleClose()}
              >
                キャンセル
              </Button>
            </div>
          </form>,
        ]}
      >
        <div
          className="mt-6"
          dangerouslySetInnerHTML={{
            __html: confirmRatingLesson(courseInfo?.title || ''),
          }}
        />
      </BasicModal>
    </>
  );
};

export default EvaluationForm;

const TextFieldCus = styled(TextField)`
  textarea {
    width: 100%;
    height: 100%;
    color: #000 !important;
    min-height: 92px;
    max-height: 250px;
  }
`;
