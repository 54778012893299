import { DatePicker, Select } from 'antd';
import Label from '../atoms/Label';
import { dateFormat, timeFormat, TrashIcon } from '../../constants';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { BasicModal } from '../molecules/BasicModal';
import Button from '../atoms/Button';
import { useEffect, useState } from 'react';
import {
  fieldRequestDemoRequired,
} from '../../constants/message';
import _ from 'lodash';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faClock } from '@fortawesome/free-solid-svg-icons';
import { getTimeBusySystem } from '../../services/Calendar';
import dayjs from 'dayjs';

interface IProps {
  onDelete: Function;
  num: number;
  index: number;
  FormCSDemo: any;
  status: number;
  statusDisable: number[];
  busyTime: any[];
  freeTime: any;
  haveBusyTime: any;
  deleteIndex: number | undefined;
  holidayJP: string[];
}

export const TimeSchedule = (props: IProps) => {
  const { onDelete, num, index, FormCSDemo, status, statusDisable, busyTime, freeTime, haveBusyTime, deleteIndex, holidayJP } =
    props;
  const {
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
    clearErrors,
  } = FormCSDemo;
  const timeZone = 'Asia/Tokyo';
  const dateEventFormat = 'YYYY-MM-DD';
  moment.tz.setDefault("Asia/Tokyo");
  const [isDelete, setIsDelete] = useState(false);
  const [firstTimeData, setFirstTimeData] = useState<any>([]);
  const [secondTimeData, setSecondTimeData] = useState<any>([]);

  const busyAllDay = busyTime.reduce((acc, period) => {
    const formattedDate = moment(
      period?.start?.dateTime || period?.start?.date,
    ).format(dateEventFormat);
    if (!acc.includes(formattedDate)) {
      acc.push(formattedDate);
    }
    return acc;
  }, []);

  const addMinutes = (time: string, minutesToAdd: number): string => {
    const timeMoment = moment(time, timeFormat);
    timeMoment.add(minutesToAdd, 'minutes');
    return timeMoment.format(timeFormat);
  }

  const removeDuplicates = (events: any) => {
    const uniqueEvents: any = [];
    const seen = new Set();

    events.forEach((event: any) => {
      const start = event.start.dateTime;
      const end = event.end.dateTime;
      const key = `${start}-${end}`;

      if (!seen.has(key)) {
        seen.add(key);
        uniqueEvents.push(event);
      }
    });

    return uniqueEvents;
  }
  
  const getTimeEvent = (events: any) => {
    let isAllDay = false;
    return events.flatMap((event: any, index: any) => {
      let slots: any = [];
      let start = event?.start?.date ? moment.tz(events[0].start.date, timeZone).startOf('day') : moment.tz(event?.start?.dateTime, timeZone);
      const end = event?.start?.date ? moment.tz(events[0].end.date, timeZone).endOf('day') : moment.tz(event?.end?.dateTime, timeZone);
      if (!isAllDay) {
        while (start < end && !isAllDay) {
          let slotStart = start.clone();
          let slotEnd = start.clone().add(30, 'minutes'); // 30 phút sau
          if (slotEnd > end) {
            slotEnd = end;
          } else {
            slots.push({
              "summary": event.summary,
              "start": {
                "dateTime": slotStart.format(),
                "timeZone": timeZone
              },
              "end": {
                "dateTime": slotEnd.format(),
                "timeZone": timeZone
              }
            });
          }
          start.add(30, 'minutes');
          // check case all day
          if (event?.start?.date && start?.format(timeFormat) === '00:00' && slotEnd?.format(timeFormat) === '00:00') {
            isAllDay = true;
          }
        }
      }

      return slots;
    });
  };

  const isOverlapping = (slot: any, busy: any) => {
    const slotStart = moment.tz(slot.start.dateTime, timeZone);
    const slotEnd = moment.tz(slot.end.dateTime, timeZone);

    return busy.some((b: any) => {
      const busyStart = moment.tz(b.start.dateTime, timeZone);
      const busyEnd = moment.tz(b.end.dateTime, timeZone);
      return slotStart.isBefore(busyEnd) && slotEnd.isAfter(busyStart);
    });
  };

  const filterTimeNotBusy = (events: any, busy: any) => {
    const slots = getTimeEvent(events);
    return slots.filter((slot: any) => !isOverlapping(slot, busy));
  };

  const getTimeStart = async (day?: string) => {
    // get event free time by day
    const filteredEvents = freeTime.filter((event: any) => {
      const startDateTime = moment.tz(event?.start?.date ?? event?.start?.dateTime, timeZone);
      const formattedStartDate = startDateTime.format(dateEventFormat);
      return formattedStartDate === day;
    });
    // sort all day have first position
    const sortedEventsWithAllDay = filteredEvents.sort((a: any, b: any) => {
      const firstStartDate = a.start.date ? moment(a.start.date).format(dateEventFormat) : null;
      const secondStartDate = b.start.date ? moment(b.start.date).format(dateEventFormat) : null;
      if (firstStartDate === day && secondStartDate !== day) {
        return -1;
      } else if (firstStartDate !== day && secondStartDate === day) {
        return 1;
      }
      return 0;
    });
    // get event busy time by day
    const filteredHasEvents = haveBusyTime.filter((event: any) => {
      const startDateTime = moment.tz(event?.start?.date ?? event?.start?.dateTime, timeZone);
      const formattedStartDate = startDateTime.format(dateEventFormat);
      return formattedStartDate === day;
    });

    // remove value duplicate
    const mergedEvents = removeDuplicates(sortedEventsWithAllDay);
    const mergedHasEvents = removeDuplicates(filteredHasEvents);
    let timeBusySystem: any = [];
    if (day && day !== "Invalid date") {
      await getTimeBusySystem([day]).then((result: any) => {
        timeBusySystem = result;
      });
    }
    const mergedAllTimebBusy = [...mergedHasEvents, ...timeBusySystem];
    const result = filterTimeNotBusy(mergedEvents, mergedAllTimebBusy);
    result.sort((a: any, b: any) => {
      const firstTime = new Date(a.start.dateTime).getTime();
      const secondTime = new Date(b.start.dateTime).getTime();
      return firstTime - secondTime;
    });
    // get value format input
    const sortResult = result.map((it: any) => {
      const date = it?.start?.dateTime;
      const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[\+\-]\d{2}:\d{2})$/
      const isValidISO8601 = iso8601Regex.test(date);
      if (isValidISO8601) {
        const timeMatch = date.match(/T(\d{2}:\d{2}):/);
        const time = timeMatch ? timeMatch[1] : null;
        // Not display 23h30 and 23h45 is start Time
        if (time && time !== '23:30' && time !== '23:45') {
          return { value: time, label: time };
        }
      }
      return null;
    }).filter((item: any) => item !== null);
    // remove value duplicate
    const uniqueTimes = Array.from(new Set(sortResult.map((time: any) => time.value)))
      .map(value => {
        return sortResult.find((time: any) => time.value === value);
      });

    return [uniqueTimes, mergedEvents];
  };

  const isEndTimeDisplay = (startTime: any, endTime: any, freeTimeData: any) => {
    let isCanAddValueEndTime = false;
    for (let i = 0; i <= freeTimeData.length - 1; i++) {
      const data = freeTimeData[i];
      if (data?.start?.date) {
        isCanAddValueEndTime = true;
        break;
      }
      const startTimeSelect = moment(startTime, timeFormat).format(timeFormat);
      const startTimeCalendar = moment(data?.start?.dateTime).tz(timeZone)?.format(timeFormat);
      const isTimeGreater = moment(startTimeSelect, timeFormat).isSameOrAfter(moment(startTimeCalendar, timeFormat));
      const endTimeSelect = moment(endTime, timeFormat).format(timeFormat);
      const endTimeCalendar = moment(data?.end?.dateTime).tz(timeZone)?.format(timeFormat);
      const isTimeLessThan = moment(endTimeSelect, timeFormat).isSameOrBefore(moment(endTimeCalendar, timeFormat));
      // if start time >= start time event calendar, end time < end time event calendar 
      if (isTimeGreater && isTimeLessThan) {
        isCanAddValueEndTime = true;
        break
      }
    }
    return isCanAddValueEndTime;
  };

  const handleChangeDay = async (e: any) => {
    setFirstTimeData([]);
    const [data, freeTimeData] = await getTimeStart(moment(new Date(e?.$d)).format(dateEventFormat))
    setFirstTimeData(data)
    setValue(`start_${index}`, null);
    setValue(`end_${index}`, null);
  }

  const handleChangeFirstTime = async (value: any) => {
    const day = moment(new Date(watch(`date${index}`)?.$d)).format(dateEventFormat)
    const [firstTimes, freeTimeData] = await getTimeStart(day);
    setFirstTimeData(firstTimes)
    if (value && firstTimes) {
      let arr: any = []
      let timeToCheck = value;
      for (let i = 0; i <= firstTimes.length - 1; i++) {
        if (firstTimes[i]?.value === timeToCheck) {
          // Option show end time
          if (isEndTimeDisplay(value, firstTimes[i]?.value, freeTimeData)) {
            timeToCheck = addMinutes(timeToCheck, 30);
            if (isEndTimeDisplay(value, timeToCheck, freeTimeData)) {
              arr.push({
                value: timeToCheck,
                label: timeToCheck,
              })
            }
          } else {
            break;
          }
        }
      }
      setSecondTimeData(arr);
      setValue(`end_${index}`, addMinutes(value, 30));
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const day = moment(new Date(watch(`date${index}`)?.$d));
      const startTime = watch(`start_${index}`);
      if (deleteIndex) {
        clearErrors(`start_${deleteIndex}`)
        clearErrors(`end_${deleteIndex}`)
      }
      if (day.isValid()) {
        clearErrors(`date${index}`);
        clearErrors(`start_${index}`);
        clearErrors(`end_${index}`);
        const [firstTimes, freeTimeData] = await getTimeStart(day.format(dateEventFormat));
        setFirstTimeData(firstTimes)
        if (startTime && firstTimes) {
          let arr: any = []
          let timeToCheck = startTime;
          for (let i = 0; i <= firstTimes.length - 1; i++) {
            if (firstTimes[i]?.value === timeToCheck) {
              // Option show end time
              if (isEndTimeDisplay(startTime, firstTimes[i]?.value, freeTimeData)) {
                timeToCheck = addMinutes(timeToCheck, 30);
                if (isEndTimeDisplay(startTime, timeToCheck, freeTimeData)) {
                  arr.push({
                    value: timeToCheck,
                    label: timeToCheck,
                  })
                }
              } else {
                break;
              }
            }
          }
          setSecondTimeData(arr);
        }
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(`date${index}`), errors, watch(`start_${index}`), deleteIndex]);

  return (
    <div className="p-4 rounded-md bg-color-blue-5 mb-2">
      <Label label={`${num === 2 ? `日時${index + 1}` : '日時'}`}></Label>
      <div className="flex gap-x-2">
        <div className="grow shrink basis-0 grid grid-cols-2 gap-4">
          <Controller
            control={control}
            name={`date${index}`}
            rules={{ required: fieldRequestDemoRequired }}
            render={({ field: { onChange, value } }) => (
              <div className="flex flex-col h-full">
                <DatePicker
                  popupClassName="cpickerdropdown"
                  size="large"
                  placeholder={dateFormat}
                  format={dateFormat}
                  onChange={e => {
                    onChange(e);
                    handleChangeDay(e);
                  }}
                  disabled={statusDisable?.includes(status)}
                  value={value}
                  disabledDate={current => {
                    return (
                      (current && (current.day() === 0 || current.day() === 6)) ||
                      !busyAllDay?.includes(current.format(dateEventFormat)) || holidayJP?.includes(current.format(dateEventFormat))
                    );
                  }}
                  minDate={dayjs().add(2, 'week')}
                  style={{ height: 48 }}
                  status={errors?.[`date${index}`] ? 'error' : ''}
                />
                {(errors?.[`date${index}`]) && (
                  <span style={{ color: 'red' }} className="relative block">
                    {errors[`date${index}`]?.message}
                  </span>
                )}
              </div>
            )}

          />


          <div className="d-flex">
            <SelectContainer
              className={`${getValues(`date${index}`) ? 'bg-white' : 'bg-[#0000000a]'}`}
            >
              <Controller
                control={control}
                name={`start_${index}`}
                rules={{ required: fieldRequestDemoRequired }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      className="w-1/2 h-full"
                      placeholder={timeFormat}
                      value={value}
                      disabled={!getValues(`date${index}`)}
                      onChange={e => {
                        onChange(e);
                        handleChangeFirstTime(e);
                      }}
                      options={firstTimeData}
                      suffixIcon={null}
                      notFoundContent="登録できる空き時間はもうありません。"
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name={`end_${index}`}
                rules={{ required: fieldRequestDemoRequired }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      className="w-1/2 h-full"
                      placeholder={timeFormat}
                      disabled={!getValues(`start_${index}`)}
                      onChange={onChange}
                      value={value}
                      options={secondTimeData}
                      suffixIcon={null}
                    />
                  );
                }}
              />
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                icon={faArrowRight}
              />
              <FontAwesomeIcon
                icon={faClock}
                className="opacity-[0.87] right-[11px] absolute fill-color-grey-01"
              />
            </SelectContainer>

            {(errors?.[`start_${index}`] || errors?.[`end_${index}`]) && (
              <span style={{ color: 'red' }} className="relative block">
                {errors[`start_${index}`]?.message ||
                  errors[`end_${index}`]?.message}
              </span>
            )}
          </div>
        </div>
        <button
          type="button"
          className={`w-8 h-12 rounded inline-flex items-center justify-center p-0 border-0 m-0 ${num === 1 || statusDisable?.includes(status) ? 'bg-color-gray-30' : 'bg-color-red-600'}`}
          disabled={num === 1 || statusDisable?.includes(status)}
          onClick={() => setIsDelete(true)}
        >
          <TrashIcon color={num === 2 ? '#FFFFFF' : '#C0C2C5'} />
        </button>
      </div>

      <BasicModal
        title=""
        open={isDelete}
        onOk={() => setIsDelete(false)}
        onCancel={() => setIsDelete(false)}
        centered
        width="680px"
        footer={[
          <>
            <div className="flex flex-row-reverse">
              <Button
                type="button"
                variant="primary"
                size="small"
                className="w-[20%]"
                onClick={() => {
                  onDelete(index);
                  setIsDelete(false);
                }}
              >
                削除
              </Button>
              <Button
                type="button"
                variant="outline-tertiary"
                size="small"
                className="w-[20%] mr-4"
                onClick={() => setIsDelete(false)}
              >
                キャンセル
              </Button>
            </div>
          </>,
        ]}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: getValues(`date${index}`)
              ? `${moment(getValues(`date${index}`).$d).format(dateFormat)}　${getValues(`start_${index}`) ? `${getValues(`start_${index}`)} - ${getValues(`end_${index}`)}` : ''} \nの日程を削除してもよろしいですか？`.replace(
                /\n/g,
                '<br />',
              )
              : '日程を削除してよろしいですか？'.replace(/\n/g, '<br />'),
          }}
        />
      </BasicModal>
    </div>
  );
};

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 6px;
  /* padding: 0 11px; */
  height: 48px;
  position: relative;
  &:hover {
    border-color: rgb(101, 153, 237);
  }

  .ant-select-focus {
    border-color: #fff !important;
    background: #fff;
  }

  .ant-select {
    flex: 1;
    padding: 0 11px;
    border: none;

    .ant-select-selector {
      border: none;
      box-shadow: none;
      padding: 0;
      background-color: inherit !important;
      border-color: #fff !important;
      box-shadow: 0 0 0 0 #fff !important;
      &:focus,
      &:active {
        border-color: #fff !important;
        box-shadow: 0 0 0 0 #fff !important;
      }
    }
  }

  .ant-select + .ant-select {
    margin-left: 8px;
  }
`;
