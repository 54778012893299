// import { Input } from 'antd';
// import { Button } from 'antd';
// import {
//   UserOutlined,
//   EyeTwoTone,
//   EyeInvisibleOutlined,
// } from '@ant-design/icons';
import Button from '../../components/atoms/Button';
import { rc, RouteKey } from '../../constants/router';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';

import {
  faUser,
  faEye,
  faKey,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../services/AuthService';
import TextFieldForm from '../../components/atoms/TextFieldForm';
import { useState } from 'react';
import {
  getUrlRedirect,
  removeCountLoginPage,
  removeUrlRedirect,
} from '../../helper/localStorage';
import {
  errorLogin,
  pwRequired,
  usernameRequired,
} from '../../constants/message';
import { Loader } from '../../components/atoms/Loader';
import RegiterModal from './included/RegiterModal';
import { ToastContainer } from 'react-toastify';

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestError, setIsRequestError] = useState<boolean>(false);
  const [isOpenRegiter, setIsOpenRegiter] = useState(false);

  const onSubmit = (data: any) => {
    setIsRequestError(false);
    setIsLoading(true);
    AuthService.login(data.username, data.password)
      .then(status => {
        if (status) {
          const url = getUrlRedirect();
          if (url) {
            removeUrlRedirect();
            removeCountLoginPage();
            navigate(url);
          } else {
            navigate(rc(RouteKey.Home).path);
          }
        } else {
          setIsLoading(false);
          setIsRequestError(true);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>ログイン</title>
        <meta name="description" content="CSA" />
      </Helmet>
      <ToastContainer
        containerId={'register-account'}
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
      <Loader isLoading={isLoading} isFullScreen={true} />
      <div className="min-w-screen min-h-screen bg-no-repeat bg-cover bg-[url('/public/assets/bg_login.png')] flex items-center justify-center p-4">
        <div className="w-[1170px] mx-auto">
          <div className="flex gap-4 justify-between">
            <div className="pt-24 inline-block text-center">
              <img className="mx-auto block" src="/assets/logo.svg" alt="" />
              <h3 className="mb-0 mt-8 text-white text-heading-1 font-semibold drop-shadow-cs-1">
                <span className="font-black">CS</span> Assessment
              </h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white rounded-lg drop-shadow-cs-2 p-8 h-min-[420px] w-[450px]">
                <h3 className={`text-heading-3 font-semiboldtext-black ${isRequestError ? 'mb-2' : 'mb-6'}`}>
                  ログイン
                </h3>
                {
                  isRequestError && (
                    <>
                      <span className='text-red-600'>
                        {errorLogin}
                      </span>
                    </>
                  )
                }
                <TextFieldForm
                  className={`mb-3 ${isRequestError ? 'mt-2' : ''}`}
                  classNameChild="w-full"
                  placeholder="メールアドレスまたはユーザーID"
                  autoComplete="username"
                  iconLeft={faUser}
                  {...register('username', {
                    required: usernameRequired,
                  })}
                  onerror={errors.username?.message ? true : false}
                  errorMessage={errors.username?.message}
                />
                <TextFieldForm
                  className=""
                  classNameChild="w-full"
                  placeholder="パスワード"
                  autoComplete="current-password"
                  iconLeft={faKey}
                  iconRight={visible ? faEyeSlash : faEye}
                  onClickIconRight={() => setVisible(!visible)}
                  canClickIconRight={true}
                  type={visible ? 'text' : 'password'}
                  {...register('password', {
                    required: pwRequired,
                  })}
                  onerror={errors.password?.message ? true : false}
                  errorMessage={errors.password?.message}
                />
                <Button type="submit" variant="primary" className={`w-full ${errors.username?.message || errors.password?.message ? 'mt-4' : 'mt-10'}`}>
                  ログイン
                </Button>
                <div className="mt-6 text-center text-base">
                  パスワードを忘れ方は
                  <Link
                    to={rc(RouteKey.ForgotPassword).path}
                    className="text-color-red-500"
                  >
                    こちら
                  </Link>
                </div>
                <div className="mt-2 text-center text-xs">
                  <Link
                    to='https://www.he.u-tokyo.ac.jp/privacy_policy/'
                    className="text-color-red-500"
                    target='_blank'
                  >
                    プライバシーポリシー
                  </Link>
                </div>

                <div className="flex justify-center mt-10">
                  <Button type="button" variant="primary" size='medium' onClick={() => { setIsOpenRegiter(true) }}>
                    アカウント作成
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        isOpenRegiter && (
          <RegiterModal isOpenRegiter={isOpenRegiter} setIsOpenRegiter={setIsOpenRegiter} />
        )
      }
    </>
  );
};
export default LoginPage;
